// src/components/Experience.js
import React from 'react';

function Experience() {
  return (
    <section id="experience">
      <h2 className="numbered-heading">Where I've Worked</h2>
      <p>
        I have gained experience across various industries, from e-commerce to educational websites and social platforms. My skill set covers a range of technologies, allowing me to adapt to diverse project needs.
      </p>
      
      <p>
        <strong>Business Local</strong><br />
        Web Developer Intern (2024 - Present)<br />
        At Business Local, I work on developing and maintaining web pages and widgets. My responsibilities include enhancing site functionality using HTML, CSS, and JavaScript, as well as managing code within Brilliant Directories to improve user experience.
      </p>
      
      <p>
        <strong>LivFree Company</strong><br />
        Full Stack Developer Intern (Oct 2024 – Present)<br />
        • Collaborate with cross-functional teams to design, develop, and maintain full-stack web applications.<br />
        • Utilize frontend technologies such as React.js, HTML, CSS, and JavaScript to build interactive user interfaces.<br />
        • Participate in code reviews, agile development meetings, and brainstorming sessions to enhance project delivery.<br />
        • Gain exposure to version control tools like Git and cloud platforms (AWS or others) for hosting solutions.
      </p>
      
      <p>
        <strong>TRIAD EDGE DESIGN</strong><br />
        Application Developer (Contract) (Sep 2024 – Present | Melbourne, VIC - Remote)<br />
        • Led the development of The Glyph Application for Android and iOS using Flutter.<br />
        • Integrated Firebase for real-time database, authentication, and cloud functions.<br />
        • Managed version control with GitHub and collaborated through Asana and Slack for task tracking and team communication.<br />
        • Conducted testing across multiple devices to ensure app stability and user satisfaction.<br />
        • Assisted with app deployment on Google Play and Apple App Store, ensuring compliance with guidelines.<br />
        • Incorporated client feedback to optimize user experience and ensure timely, high-quality delivery.
      </p>
    </section>
  );
}

export default Experience;

